import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import About from "./pages/about";
import HRSection from "./pages/Hr";
import NotFound from "./pages/NotFound";
import PMP from "./pages/PMP";
import SupplyChain from "./pages/SupplyChain";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses/hr" element={<HRSection />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/PMP" element={<PMP />} />
        <Route path="/SupplyChain" element={<SupplyChain />} />
      </Routes>
    </Router>
  );
}

export default App;
