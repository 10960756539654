import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FirstNav from "../components/Navbar";
import Footer from "../components/Footer";
import PartnerSlide from "../components/PartnerCarousel";
import LogoSeparator from "../components/separator";
import Companyslider from "../components/Companies";
// import UnderConstruction from "../components/Underconstruct";

function About() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>About Talent Track Certification Programs</title>
        <meta
          name="description"
          content="Talent Track Certification programs is a premier training company dedicated to empowering professionals in Dubai, Gulf countries, and globally with comprehensive online and offline HR courses. Our mission is to equip professionals with essential HR skills and knowledge to succeed in the dynamic field of Human Resources."
        />
      </Helmet>
      <FirstNav />
      <Container className="mt-2 text-center">
        <Row>
          <Col lg={12} className="mx-auto" data-aos-duration="1000">
            <h2 className="mt-4 mb-4">About Us</h2>
            <p>
              Talent Track Certification programs is a premier training
              company dedicated to empowering professionals in Dubai, Gulf
              countries, and around the globe with comprehensive online and
              offline HR courses. With a focus on bridging the skills gap and
              meeting the increasing demand for HR professionals in these
              regions, Talent Track is committed to delivering high-quality
              training programs that enhance knowledge and capabilities.
            </p>
            <p data-aos="fade-up">
              Our mission at Talent Track is to equip professionals in Dubai,
              Gulf countries, and beyond with the essential skills and knowledge
              to thrive in the dynamic and competitive field of Human Resources.
              We strive to offer courses that are not only relevant and
              practical but also aligned with the latest HR trends and best
              practices.
            </p>
            <p data-aos="fade-up">
              Our objectives include providing top-notch online and offline 
              courses tailored to the specific needs of professionals in Dubai
              and Gulf countries, establishing ourselves as a leading provider
              of HR training programs in the region, exceeding customer
              expectations, expanding our course offerings based on market
              demand, and forging strategic partnerships to enhance the reach
              and impact of our training programs.
            </p>
            <p data-aos="fade-up">
              Our services include workshop-style training sessions conducted
              in-person at designated venues, customized programs designed to
              meet the specific needs of organizations or groups, and practical
              exercises, case studies, and group discussions to facilitate
              learning and skill application.
            </p>
          </Col>
        </Row>
      </Container>
      {/* <LogoSeparator /> */}
      {/* <PartnerSlide /> */}
      {/* <LogoSeparator /> */}
      {/* <Companyslider /> */}
      {/* <UnderConstruction /> */}

      <Footer />
    </>
  );
}

export default About;
