import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import logo from "../assets/images/TT.svg";

const LogoSeparator = () => {
  const borderColor = useColorModeValue("#023047", "white");

  return (
    <Box textAlign="center " className="mt-3">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          className="mt-4"
          flex="1"
          borderBottom={`2px solid ${borderColor}`}
          mr="2.5rem"
        />
        <img
          src={logo}
          alt="Website Logo"
          style={{ width: "120px", height: "auto" }}
        />
        <Box
          flex="1"
          className="mt-4"
          borderBottom={`2px solid ${borderColor}`}
          ml="1rem"
        />
      </Box>
    </Box>
  );
};

export default LogoSeparator;
