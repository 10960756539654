import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
function SocialMediaIcons() {
  const email = "info@talenttrackedu.com";
  const phoneNumber = "+971542157750";
  return (
    <aside>
      <Box
        position="fixed"
        top="50%"
        left="1"
        transform="translateY(-50%)"
        zIndex="999"
        className="socialLinkbox"
      >
        <Flex direction="column">
          <a className="socialLink m-4" href={`mailto:${email}`} aria-label="Email">
            <EmailIcon />
          </a>
          <a className="socialLink m-4" href={`tel:${phoneNumber}`} aria-label="Phone">
            <PhoneIcon />
          </a>
          <a
            className="socialLink m-4"
            href="https://www.facebook.com/profile.php?id=61556986539113"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            className="socialLink m-4"
            href="https://twitter.com/Talent_Track11"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            className="socialLink m-4"
            href="https://www.youtube.com/channel/UCxvhE-mBpNuPWsc1ifF9OIQ"
            target="_blank"
            rel="noreferrer"
            aria-label="YouTube"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a
            className="socialLink m-4"
            href="https://www.instagram.com/talenttrack11/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </Flex>
      </Box>
    </aside>
  );
}
export default SocialMediaIcons;
