import React from 'react';
import { useColorMode, Button, Box } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

function DarkModeToggle() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box position="fixed" bottom="4" right="4" zIndex="999">
      <Button onClick={toggleColorMode} size="lg">
        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
      </Button>
    </Box>
  );
}

export default DarkModeToggle;
