import React, { useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import { Container, Col } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "../assets/styles/Cards.css";

function EnglishCourse() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <section
      className="course-grid-section"
      style={{ display: "flex", minHeight: "100vh", alignItems: "center" }}
      id="Courses-section"
    >
      <Helmet>
        <meta
          name="description"
          content="Explore a diverse range of courses offered by Talent Track to enhance your skills and advance your career. From HR Diploma to Graphic Design, TEFL to Digital Marketing, our meticulously crafted courses are designed to equip you with the knowledge and expertise needed to excel in today's competitive job market. Start your journey towards personal and professional growth with Talent Track's comprehensive learning solutions. Join us today and unlock your potential!"
        />
      </Helmet>
      <Container className="text-center">
        <h1 className="mt-2">Tests of English as a Foreign Language</h1>
        <Box className="ag-courses_box">
          <Col
            lg={4}
            md={6}
            sm={12}
            className="ag-courses_item"
            data-aos="fade-down"
          >
            <a href="#" className="ag-courses-item_link">
              {/* <Image src="../assets/images/13146644_School teacher explaining geography lesson.svg" alt="Course Image" /> */}
              <div
                className="ag-courses-item_bg"
                style={{ backgroundColor: "#2364aa" }}
              ></div>
              <div className="ag-courses-item_title">
                Test of English as a Foreign Language{" "}
              </div>

              <div className="ag-courses-item_date-box">
                <p>
                  <span className="ag-courses-item_date">TOEFL</span>
                </p>
              </div>
            </a>
          </Col>

          <Col
            lg={4}
            md={6}
            sm={12}
            className="ag-courses_item"
            data-aos="fade-down"
          >
            <a href="#" className="ag-courses-item_link">
              <div
                className="ag-courses-item_bg"
                style={{ backgroundColor: "#495057" }}
              ></div>

              <div className="ag-courses-item_title">
                International English Language Testing System{" "}
              </div>

              <div className="ag-courses-item_date-box">
                <p>
                  <span className="ag-courses-item_date">IELTS</span>
                </p>
              </div>
            </a>
          </Col>
        </Box>
      </Container>
    </section>
  );
}

export default EnglishCourse;
