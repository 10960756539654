import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useColorModeValue } from "@chakra-ui/react";
import "../assets/styles/navbar.css";
import "../assets/styles/particles.scss";
import DarkModeToggle from "./colorMode";
import SocialMediaIcons from "./socialmedia";

function FirstNav() {
  const bgColor = useColorModeValue("#fff", "#14213d");
  const textColor = useColorModeValue("black", "white");
  const navLinkColor = useColorModeValue("#14213d", "#e5e5e5");
  const LogoTextColor = useColorModeValue("#14213d", "#e5e5e5");
  return (
    <Navbar
      expand="lg"
      className="TheNavbar text-center"
      style={{ backgroundColor: bgColor }}
    >
      <Container fluid>
        <div class="animation-wrapper">
          <div class="particlee particlee-2"></div>
          <div class="particlee particlee-4"></div>
        </div>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            className="logo m-0"
            src="../assets/images/TT.svg"
            width={80}
            height={80}
            alt="Website-logo"
          />
          <span className="mt-4 logoTEXT" style={{ color: LogoTextColor }}>
            Education
          </span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto"
            style={{ maxHeight: "100px", color: textColor }}
            navbarScroll
          >
            <Nav.Link
              className="ThenavLinks"
              href="/"
              style={{ color: navLinkColor }}
            >
              Home
            </Nav.Link>

            <Nav.Link
              className="ThenavLinks"
              href="#Courses-section"
              style={{ color: navLinkColor }}
            >
              Courses
            </Nav.Link>
            <Nav.Link
              className="ThenavLinks"
              href="/about"
              style={{ color: navLinkColor }}
            >
              About Us
            </Nav.Link>
          </Nav>
          <Navbar.Brand href="/" className="m-0 justify-content-end">
            <img
              className="logo"
              src="../assets/images/quil-logo.svg"
              width={60}
              height={60}
              alt="Second-logo"
            />
          </Navbar.Brand>
        </Navbar.Collapse>
            <DarkModeToggle aria-label="Toggle dark mode" />
            <SocialMediaIcons />

      </Container>
    </Navbar>
  );
}

export default FirstNav;
