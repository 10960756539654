import React from "react";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import "../assets/styles/carousel.css";

const PartnerCarousel = () => {
  const options = {
    loop: true,
    nav: true,
    margin: false,
    padding: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 10000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const partners = [
    { id: 1, src: "../assets/images/5.jpg", alt: "HR Course", caption: "" },
    { id: 2, src: "../assets/images/2.jpg", alt: "Book Now", caption: "" },
    {
      id: 3,
      src: "../assets/images/3.jpg",
      alt: "Courses Numbers",
      caption: "",
    },
    { id: 4, src: "../assets/images/4.jpg", alt: "GAT Tests", caption: "" },
    { id: 5, src: "../assets/images/1.jpg", alt: "HR Diploma", caption: "" },
  ];

  return (
    <section className="partner-carousel">
      <Container fluid>
        <div className="carousel-wrapper">
          <OwlCarousel options={options}>
            {partners.map((partner) => (
              <article key={partner.id} className="partner-item">
                <div
                  className="image-container"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={partner.src}
                    alt={partner.alt}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                    }}
                    loading="lazy"
                  />
                </div>
              </article>
            ))}
          </OwlCarousel>
        </div>
      </Container>
    </section>
  );
};

export default PartnerCarousel;
