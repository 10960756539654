import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <ColorModeScript initialColorMode="dark" />
    <App />
  </ChakraProvider>
);
