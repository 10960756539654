import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Input } from "@chakra-ui/react";

export default function App() {
  return (
    <MDBFooter className="text-center" style={{ backgroundColor: "#1212122c" }}>
      <MDBContainer className="p-4">
        <section className="Footer">
          <form action="">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol size="auto">
                <p className="pt-2">
                  <strong>Sign Up For Our Newsletter</strong>
                </p>
              </MDBCol>

              <MDBCol md="5" start>
                <Input type="email" label="Email address" />
              </MDBCol>

              <MDBCol size="auto">
                <MDBBtn outline color="light" type="submit" className="mb-4">
                  Subscribe
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </section>
{/* <section> */}
  {/* <MDBRow className="text-center">
    <MDBRow className="mt-2 text-center">
      <MDBCol md="6" className="d-flex flex-column align-items-center">
        <h3>Our Resources Partners</h3>
        <img src="../assets/images/sliding-logo.png" alt="American Academy logo" style={{ width: '150px' }} className="mt-auto mb-auto" />
      </MDBCol>
      <MDBCol md="6" className="d-flex flex-column align-items-center">
        <h3>Our Examination Partners</h3>
        <img src="../assets/images/GAT-LOGO.svg" alt="GAT LOGO" style={{ width: '150px' }} className="mt-auto mb-auto" />
      </MDBCol>
    </MDBRow>
  </MDBRow> */}
{/* </section> */}
      </MDBContainer>

      <div className="text-center p-3" style={{ backgroundColor: "#1212122c" }}>
        © Copyright :
        <a className="text-white m-1" href="/">
          Talent Track
        </a>
      </div>
    </MDBFooter>
  );
}
