import { useColorMode } from "@chakra-ui/react";
import { Button, Row, Container } from "react-bootstrap";
import "aos/dist/aos.css";
import "../assets/styles/sections.css";

const TalentTrackSection = () => {
  const { colorMode } = useColorMode();

  return (
    <section
      className={`talent-track-section ls-after-header-second landing-section text-center ${colorMode}`}
      data-aos="fade-up"
      id="talent-track-section"
    >
      <Container>
        <Row className="mt-2 mb-2">
          <h1 className="text-color">
            Talent Track offers a wide array of courses designed to equip you
            with the knowledge and expertise needed to excel in today's
            competitive world.
          </h1>
          <h2 className="text-color">
            Whether you're part of a multinational corporation or a burgeoning
            startup,
          </h2>
          <h3 className="text-color">
            Talent Track provides tailored learning solutions to meet your
            unique requirements.
          </h3>
          <h4 className="text-color">
            Our courses are meticulously crafted to empower individuals and
            organizations alike, enabling them not only to weather the storm but
            also to emerge stronger and more resilient.
          </h4>
        </Row>
        <Button className="DiscoverButton mt-2" href="#Courses-section">
          Discover Courses
        </Button>
      </Container>
    </section>
  );
};

export default TalentTrackSection;
