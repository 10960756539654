import React from "react";
import { Helmet } from "react-helmet";
import FirstNav from "../components/Navbar";
import PartnerSlide from "../components/PartnerCarousel";
import LogoSeparator from "../components/separator";
import PartnerCarousel from "../components/Cover";
import Footer from "../components/Footer";
import TeachersCourse from "../components/Teacher-Courses";
import EnglishCourse from "../components/EnglishCourses";
import GatCourse from "../components/GatCourse";
import Companyslider from "../components/Companies";
import TalentTrackSection from "../components/TextSection";
import ManagmentCourses from "../components/mangment-Courses";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Talent Track</title>
        <meta
          name="description"
          content="Discover a wide array of courses offered by Talent Track to enhance your skills and advance your career. From HR Diploma to Digital Marketing, we provide tailored learning solutions to meet your unique requirements."
        />
        <meta
          name="keywords"
          content="Talent Track, courses, HR Diploma, Digital Marketing, learning, education"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://talenttrackedu.com/" />
      </Helmet>
      <FirstNav />
      <PartnerCarousel />
      <LogoSeparator />
      <TalentTrackSection />
      <LogoSeparator />
      <ManagmentCourses />
      <LogoSeparator />
      <TeachersCourse />
      <LogoSeparator />
      <EnglishCourse />
      <LogoSeparator />
      <GatCourse />
      {/* <LogoSeparator /> */}
      {/* <LogoSeparator /> */}
      {/* <PartnerSlide /> */}
      {/* <LogoSeparator /> */}
      {/* <Companyslider />  */}
      <Footer />
    </>
  );
}

export default HomePage;
